<template>
  <Feature
    v-slot="{ feature }"
    feature-slug="cms"
  >
    <div>
      <div
        v-if="company.id"
        class="flex gap-4"
      >
        <button
          class="btn-primary"
          :loading="loading"
          :disabled="loading"
          @click="updateWidgetType"
        >
          {{ cmsOrganization?.widgetType === "WIDGET" ? 'IFrames aktivieren' : 'Widgets aktivieren' }}
        </button>
        <button
          :class="cmsOrganization?.id ? 'btn-secondary' : 'btn-primary'"
          :loading="loading"
          :disabled="loading"
          @click="createOrUpdateCmsOrganizations"
        >
          {{ cmsOrganization?.id ? 'CMS Eintrag für Company updaten' : 'CMS Eintrag für Company anlegen' }}
        </button>
        <button
          v-if="feature.config.canActivateAilanderLandingpages && cmsOrganization?.id && !cmsOrganization?.isAilanderLandingpagesActive"
          class="btn-primary"
          :loading="loading"
          :disabled="loading"
          @click="activateAilanderLandingpages"
        >
          Ailander Landingpages aktivieren
        </button>
        <button
          v-if="feature.config.canActivateAilanderLandingpages && cmsOrganization?.id && cmsOrganization?.isAilanderLandingpagesActive"
          class="btn-primary"
          :loading="loading"
          :disabled="loading"
          @click="deactivateAilanderLandingpages"
        >
          Ailander Landingpages deaktivieren
        </button>
      </div>
    </div>
  </Feature>
</template>

<script>
import GET_CMS_ORGANIZATION from '@/graphql/GetCmsOrganization.gql'
import UPDATE_CMS_MAPPING from '@/graphql/CreateOrUpdateCmsOrganizations.gql'
import UPDATE_WIDGET_TYPE from '@/graphql/UpdateWidgetType.gql'
import ACTIVATE_AILANDER_LANDINGPAGES from '@/graphql/ActivateAilanderLandingpages.gql'
import DEACTIVATE_AILANDER_LANDINGPAGES from '@/graphql/DeactivateAilanderLandingpages.gql'
import eventBus, { eventNames } from '@/lib/eventBus'
import { Feature } from 'feature-config'

export default {
  components: {
    Feature
  },
  props: {
    company: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      loading: false,
      checked: false
    }
  },
  apollo: {
    cmsOrganization: {
      query: GET_CMS_ORGANIZATION,
      variables () {
        return {
          companyId: this.company.id
        }
      },
      skip () {
        return !this.company?.id
      }
    }
  },
  methods: {
    async updateWidgetType () {
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_WIDGET_TYPE,
          variables: {
            input: {
              companyId: this.company.id,
              widgetType: this.cmsOrganization?.widgetType === 'WIDGET' ? 'IFRAME' : 'WIDGET'
            }
          },
          refetchQueries: [{ query: GET_CMS_ORGANIZATION, variables: { companyId: this.company.id } }]
        })
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'success', text: 'WidgetType aktualisiert' })
      } catch (e) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'error', text: 'WidgetType konnte nicht aktualisiert werden' })
      } finally {
        this.loading = false
      }
    },
    async createOrUpdateCmsOrganizations () {
      try {
        this.loading = true
        await this.$apollo.mutate({
          mutation: UPDATE_CMS_MAPPING,
          variables: {
            companyIds: [this.company.id]
          },
          refetchQueries: [{ query: GET_CMS_ORGANIZATION, variables: { companyId: this.company.id } }]
        })
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'success', text: 'CMS aktualisiert' })
      } catch (error) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'error', text: 'CMS konnte nicht aktualisiert werden' })
      } finally {
        this.loading = false
      }
    },
    async activateAilanderLandingpages () {
      try {
        this.loading = true
        await this.$apollo.mutate({
          mutation: ACTIVATE_AILANDER_LANDINGPAGES,
          variables: {
            companyIds: [this.company.id]
          },
          refetchQueries: [{ query: GET_CMS_ORGANIZATION, variables: { companyId: this.company.id } }]
        })
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'success', text: 'CMS aktualisiert' })
      } catch (error) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'error', text: 'CMS konnte nicht aktiviert werden' })
      } finally {
        this.loading = false
      }
    },
    async deactivateAilanderLandingpages () {
      try {
        this.loading = true
        await this.$apollo.mutate({
          mutation: DEACTIVATE_AILANDER_LANDINGPAGES,
          variables: {
            companyIds: [this.company.id]
          },
          refetchQueries: [{ query: GET_CMS_ORGANIZATION, variables: { companyId: this.company.id } }]
        })
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'success', text: 'CMS aktualisiert' })
      } catch (error) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'error', text: 'CMS konnte nicht deaktiviert werden' })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
