<template>
  <div>
    <div class="my-6 timeline">
      <div
        v-for="content in savedContents.savedContents"
        :key="content.id"
        class="flex flex-col items-start timeline-item md:flex-row md:items-center"
      >
        <div class="flex align-middle">
          <div class="timeline-icon">
            <div class="avatar">
              <img
                v-if="KindTypeIcon[content.textKind]"
                :src="KindTypeIcon[content.textKind]"
                class="timeline-avatar"
                alt="icon"
              >
              <span
                v-else
                class="avatar-icon-download mdi mdi-download"
              />
            </div>
          </div>
          <div class="px-2 py-1 text-xl font-bold">
            Post am {{ new Date(content.createdAt).toLocaleString() }} auf
          </div>
          <div class="flex flex-col items-start timeline-content">
            <div class="flex items-center gap-1">
              <div
                v-for="({type, permalink, postId}) in content.socialMediaPosts || []"
                :key="postId"
              >
                <a
                  :href="permalink"
                  target="_blank"
                >
                  <div class="avatar">
                    <img
                      :src="KindTypeIcon[TypeToKind[type]]"
                      class="w-10 h-10"
                      alt="social media icon"
                    >
                  </div>
                </a>
              </div>

              <div v-if="content.downloaded">
                <span>Downloaded:</span>
                <i class="text-xl mdi mdi-download-circle text-primary" />
              </div>
            </div>
          </div>
          <a
            :href="`/companies/${content.company.id}`"
            target="_blank"
            class="flex gap-2 cursor-pointer"
          >
            <div class="px-2 py-1 text-xl font-bold">
              von {{ content.company.name }}
            </div>
          </a>
        </div>
        <div class="p-4 mt-4 bg-gray-200 rounded-lg shadow-xl timeline-card md:mt-0 md:ml-4">
          <div class="flex flex-col justify-between md:flex-row">
            <div>
              <span>
                <div
                  class="content-text"
                  v-html="content.text.replace(/\n/g, '<br>')"
                />
              </span>
            </div>
            <img
              v-if="content.imagePath"
              class="w-full mt-4 rounded-lg shadow-lg md:mt-0 md:ml-4"
              :src="content.imagePath"
              style="max-width: 300px; max-height: 300px; object-fit: cover;"
              alt="content image"
            >
          </div>
        </div>
        <div class="vertical-line" />
      </div>
    </div>

    <div class="flex justify-center py-4">
      <button
        class="px-4 py-2 mx-1 font-bold bg-gray-200 rounded hover:bg-gray-300"
        :disabled="currentPage === 1"
        @click="prevPage"
      >
        {{ "&lt;" }}
      </button>
      <span class="px-4 py-2 mx-1">{{ currentPage }}</span>
      <button
        class="px-4 py-2 mx-1 font-bold bg-gray-200 rounded hover:bg-gray-300"
        :disabled="currentPage === paginationLength"
        @click="nextPage"
      >
        >
      </button>
    </div>
  </div>
</template>

<script>
import GET_SAVED_CONTENTS from './getSavedContent.gql'

const KindTypeIcon = Object.freeze({
  facebookPost: '/img/kindTypes/facebook.png',
  instagramPost: '/img/kindTypes/instagram.png',
  linkedinPost: '/img/kindTypes/linkedin.png'
})

const TypeToKind = Object.freeze({
  FACEBOOK: 'facebookPost',
  INSTAGRAM: 'instagramPost',
  LINKEDIN: 'linkedinPost'
})

export default {
  data () {
    return {
      savedContents: {
        total: 0,
        savedContents: []
      },
      currentPage: 1,
      KindTypeIcon,
      TypeToKind
    }
  },
  computed: {
    paginationLength () {
      return Math.ceil(this.savedContents.total / 20)
    }
  },
  methods: {
    resetCurrentPage () {
      this.currentPage = 1
    },
    updatePage (currentPage) {
      this.currentPage = currentPage
    },
    prevPage () {
      if (this.currentPage > 1) {
        this.currentPage--
      }
    },
    nextPage () {
      if (this.currentPage < this.paginationLength) {
        this.currentPage++
      }
    }
  },
  apollo: {
    savedContents: {
      query: GET_SAVED_CONTENTS,
      fetchPolicy: 'no-cache',
      pollInterval: 60000,
      variables () {
        return {
          input: {
            page: this.currentPage
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.divider {
  border-color: #F1F1F4;
}
.vertical-line {
  width: 2px;
  background-color: gray;
  margin: 10px;
  height: 50px;
}

.timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeline-item {
  width: 1000px;
  display: flex;
  flex-direction: column;
}

.timeline-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 80%;
  background-color: #f3f4f6;
}

.avatar img {
  border-radius: 80%;
}

.timeline-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.createdAt-content {
  font-size: 0.875rem;
  color: #6b7280;
}

.timeline-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.content-text {
  white-space: pre-wrap;
}
</style>
