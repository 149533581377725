<template>
  <section class="w-full">
    <h1 class="flex items-center justify-between">
      <div>
        Landingpages &amp; Materialien
        <Feature
          v-slot="{feature}"
          :feature-slug="featureNames.VARIANT"
        >
          <span
            v-show="company && hasMultipleVariants(feature.config.variants)"
            class="ml-2 text-base text-gray-600"
          >{{ company.variant }}</span>
        </Feature>
      </div>
      <div class="flex items-center">
        <button
          class="btn-inline-small"
          @click="showExportImplementationData()"
        >
          Implementierungsdaten
        </button>

        <span class="mx-3 text-sm font-bold text-gray-600">|</span>
        <button
          class="btn-inline-small"
          @click="showCustomUrlsModal()"
        >
          Custom Urls
        </button>
        <Feature
          v-slot="{feature}"
          :feature-slug="featureNames.VARIANT"
        >
          <div v-show="company && hasMultipleVariants(feature.config.variants)">
            <span class="mx-3 text-sm font-bold text-gray-600">|</span>
            <button
              class="btn-inline-small"
              @click="showVariantUpdateModal()"
            >
              Variante ändern
            </button>
          </div>
        </Feature>

        <span
          v-if="hasRole(ROLES.SUPER_ADMIN)"
          class="mx-3 text-sm font-bold text-gray-600"
        >|</span>
        <button
          v-if="hasRole(ROLES.SUPER_ADMIN)"
          class="btn-inline-small"
          @click="showLandingpageBulkCreateModal()"
        >
          Landingpages erstellen
        </button>

        <span
          v-if="hasRole(ROLES.SUPER_ADMIN)"
          class="mx-3 text-sm font-bold text-gray-600"
        >|</span>
        <button
          v-if="hasRole(ROLES.SUPER_ADMIN)"
          class="btn-inline-small"
          @click="showGenerateModal()"
        >
          Materialien generieren
        </button>
      </div>
    </h1>
    <div class="w-full">
      <div
        v-if="company.productType === 'BASIC'"
        class="pb-4"
      >
        <span class="pb-4 text-lg font-semibold text-gray-700">
          Gebuchte Landingpages:
        </span>
        {{ company.bookedLandingpageSlugs.join(', ') }}
      </div>
      <h3 class="text-lg font-semibold text-gray-700">
        Tools
      </h3>
      <ul>
        <li
          v-for="landingpage in tools"
          :key="landingpage.slug"
          class="w-full py-2"
        >
          <landingpage
            :landingpage="landingpage"
            :campaigns="campaigns"
            :company="company"
            :materials="getMaterialsForLandingpage(landingpage.id)"
          />
        </li>
      </ul>

      <h3 class="mt-6 text-lg font-semibold text-gray-700">
        Ratgeber
      </h3>
      <ul>
        <li
          v-for="landingpage in guides"
          :key="landingpage.slug"
          class="w-full py-2"
        >
          <landingpage
            :landingpage="landingpage"
            :campaigns="campaigns"
            :company="company"
            :materials="getMaterialsForLandingpage(landingpage.id)"
          />
        </li>
      </ul>

      <h3 class="mt-6 text-lg font-semibold text-gray-700">
        Checklisten
      </h3>
      <ul>
        <li
          v-for="landingpage in checklists"
          :key="landingpage.slug"
          class="w-full py-2"
        >
          <landingpage
            :landingpage="landingpage"
            :campaigns="campaigns"
            :company="company"
            :materials="getMaterialsForLandingpage(landingpage.id)"
          />
        </li>
      </ul>

      <h3 class="mt-6 mb-3 text-lg font-semibold text-gray-700">
        Kampagnen (ohne zugeordnete Landingpage)
      </h3>
      <div
        v-if="campaigns"
        class="relative text-gray-800 border rounded hover:shadow"
      >
        <div
          v-for="[campaignId, campignMaterials] in materialsByCampaign"
          :key="campaignId"
        >
          <h4 class="pl-4 mt-2 text-xs text-gray-600 uppercase">
            {{ campaigns.find(({id}) => id === campaignId)?.name }}
          </h4>
          <ul>
            <material-list-item
              v-for="material in campignMaterials"
              :key="material.id"
              :material="material"
            />
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  faCode,
  faTrash,
  faFolder,
  faExternalLinkAlt
} from '@fortawesome/free-solid-svg-icons'
import UpdateVariantModal from '@/components/modals/company/variant/update'
import CustomUrlsModal from '@/components/modals/company/customUrls/edit'
import ExportImplementationDataModal from '@/components/modals/company/export-implementation/export-implementation'
import BulkCreateLandingpageModal from '@/components/modals/landingpage/bulk-create'
import GenerateModal from '@/components/modals/landingpage/generate'
import role from '@/mixins/role'
import groupBy from '@/lib/groupBy'
import Landingpage from './landingpage'
import materialListItem from './material-list-item'
import MATERIALS from '@/graphql/material/materials.gql'
import LIST_CAMPAIGNS from '@/graphql/ListCampaigns.gql'
import featureMixin from '@/mixins/feature'
import variantMixin from '@/mixins/variant'

const STATUS_UPDATE_INTERVAL_MS = 10000

export default {
  components: {
    Landingpage,
    materialListItem
  },

  mixins: [role, featureMixin, variantMixin],

  props: {
    company: { type: Object, default: null }
  },

  data () {
    return {
      materials: [],
      faCode,
      faTrash,
      faFolder,
      faExternalLinkAlt
    }
  },

  computed: {
    tools () {
      return this.company.landingpages.filter(lp =>
        ['VALUATION', 'RETURN_CALL', 'LIFE_ANNUITY', 'PARTIAL_SALE', 'PLOT_VALUATION', 'QUIZZARD', 'PROPERTY_REQUEST'].includes(lp.type)
      )
    },

    guides () {
      return this.company.landingpages.filter(lp => lp.type === 'GUIDE')
    },

    checklists () {
      return this.company.landingpages.filter(lp => lp.type === 'CHECKLIST')
    },

    materialsByCampaign () {
      const materials = this.materials.filter(({ campaignId, landingpageId }) => campaignId && !landingpageId)
      return Object.entries(groupBy(materials, 'campaignId'))
    },

    isCurrentlyGenerating () {
      return this.materials.some(material => ['QUEUED', 'GENERATING'].includes(material.status))
    }
  },

  apollo: {
    materials: {
      query: MATERIALS,
      variables () {
        return {
          companyId: this.company.id
        }
      },
      skip () {
        return !this.company?.id
      }
    },

    campaigns: {
      query: LIST_CAMPAIGNS
    },

    /**
     * This query is just to update the status while materials are generated.
     * The result will be stored in this.materialsStatus but we won't
     * use it. In the background the global GraqhQL-Cache will be
     * updated as well – that's what we want.
     */
    materialsStatus: {
      query: MATERIALS,
      variables () {
        return {
          companyId: this.company.id
        }
      },
      skip () {
        return !this.materials.length || !this.isCurrentlyGenerating
      },
      update: data => data.materials,
      pollInterval: STATUS_UPDATE_INTERVAL_MS
    }
  },

  methods: {
    getMaterialsForLandingpage (landingpageId) {
      return this.materials.filter(material => material.landingpageId === landingpageId)
    },

    showExportImplementationData () {
      this.$modal.show(
        ExportImplementationDataModal,
        {
          companyId: this.company.id
        },
        { height: 'auto' }
      )
    },

    showCustomUrlsModal () {
      this.$modal.show(
        CustomUrlsModal,
        {
          landingpages: this.company.landingpages
        },
        { height: 'auto' }
      )
    },

    showVariantUpdateModal () {
      this.$modal.show(
        UpdateVariantModal,
        {
          companyId: this.company.id,
          variant: this.company.variant
        },
        { height: 'auto' }
      )
    },

    showLandingpageBulkCreateModal () {
      this.$modal.show(
        BulkCreateLandingpageModal,
        {
          companyId: this.company.id,
          landingpages: this.company.landingpages,
          variant: this.company.variant
        },
        {
          height: 'auto'
        }
      )
    },

    showGenerateModal () {
      this.$modal.show(GenerateModal, {
        companyId: this.company.id,
        materials: this.materials
      })
    }
  }
}
</script>
