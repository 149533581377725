<template>
  <div>
    <div class="flex items-center justify-between px-10 py-8 bg-gray-100 border-b rounded-t-lg">
      <h1 class="text-xl font-semibold tracking-wider text-gray-800 uppercase">
        Posts
      </h1>
    </div>
    <div class="w-full h-full">
      <Posts />
    </div>
  </div>
</template>

<script>
import Posts from '@/components/post-history/Posts.vue'
import featureMixin from '@/mixins/feature'

export default {
  components: { Posts },
  mixins: [featureMixin]
}
</script>
